<template>
    <div>
		<Modal_Parecer ref="Modal_Parecer"></Modal_Parecer>
        <ModalBigger ref="viewDocument" style="z-index: 1000;">
            <template #header>
                <div class="d-flex justify-content-between">
                <strong><span style="color: black"> PARECERES </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            </template>

            <template #body>

                <div
                    v-if="parecer_proc == null && parecer_justice == null && parecer_education == null && parecer_farm == null && parecer_health == null && parecer_human == null && parecer_infra == null"
                    class="d-flex justify-content-center">
                    <h1>Nunhum parecer disponivel</h1>
                </div>

                <div v-else>
                    <div class="d-flex justify-content-center mb-2 text-black"> 
                        <span>
                            <strong> SELECIONE UM PARECER DISPONIVEL PARA VISUALIZAR </strong>
                        </span>
                    </div>


                <div v-if="parecer_proc_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black"> 
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(7)">
                        <span>
                            <strong> PARECER PROCURADOR </strong>
                        </span>
                    </button>
                    </div>
                </div>


                <div v-if="parecer_education_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black">
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(1)">
                        <span>
                            <strong> PARECER: {{ comission_one }} </strong>
                        </span>
                        </button>
                    </div>

                </div>

                <div v-if="parecer_farm_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black">
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(2)">
                        <span>
                            <strong> PARECER: {{ comission_two }} </strong>
                        </span>
                    </button>
                    </div>

                </div>

                <div v-if="parecer_infra_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black">
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(3)">
                        <span>
                            <strong> PARECER: {{ comission_three }} </strong>
                        </span>
                    </button>
                    </div>

                </div>

                <div v-if="parecer_health_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black">
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(4)">
                        <span>
                            <strong> PARECER: {{ comission_four }} </strong>
                        </span>
                    </button>
                    </div>

                </div>

                <div v-if="parecer_human_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black">
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(5)">
                        <span>
                            <strong> PARECER: {{ comission_five }} </strong>
                        </span>
                    </button>
                    </div>

                </div>

                <div v-if="parecer_justice_status == true" class="mb-2">
                    <div class="d-flex justify-content-center mb-2 text-black"> 
                        <button type="button" class="btn btn-success" @click="showParecer_Modal(6)">
                        <span>
                            <strong> PARECER: {{ comission_six }} </strong>
                        </span>
                    </button>
                    </div>
                </div>

            </div>
            </template>

            <template #footer>


            </template>
        </ModalBigger>

    </div>
</template>

<script>
import ModalBigger from './ModalBig.vue';
import { AxiosAPI } from '@/axios';
import Modal_Parecer from '../Modals/Modal_Parecer.vue';
import { useMainStore } from '../../../stores/index';
import { useBusStore } from '../../../stores/index';

export default {
	components: {
		ModalBigger,
		Modal_Parecer
	},

	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			parecer_education: null,
			parecer_farm: null,
			parecer_infra: null,
			parecer_health: null,
			parecer_human: null,
			parecer_justice: null,
			parecer_proc: null,
			parecer_education_id: null,
			parecer_farm_id: null,
			parecer_infra_id: null,
			parecer_health_id: null,
			parecer_human_id: null,
			parecer_justice_id: null,
			parecer_education_status: false,
			parecer_farm_status: false,
			parecer_infra_status: false,
			parecer_health_status: false,
			parecer_human_status: false,
			parecer_justice_status: false,
			parecer_proc_status: false,
			comission_one: '',
			comission_two: '',
			comission_three: '',
			comission_four: '',
			comission_five: '',
			comission_six: '',
		};
	},

	watch: {
		'Bus.RemoveParecerView': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.RemoveParecerView = null;
				this.Bus.RemoveParecerModalView = true;
				this.$refs.viewDocument.closeModal();
			}
		},
	},

	methods: {
		Name_Comission(){
			if(this.StoreAll.host == 'dev'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'teste'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'ja'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Políticas gerais';

			}else if(this.StoreAll.host == 'jc'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'bodo'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'pedroavelino'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'pedrapreta'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'campogrande'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'lajes'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'parazinho'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'jandaira'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Agricultura, obras, transporte e urbanismo';
				this.comission_four = 'Educação, cultura, esporte, lazer, meio ambiente e turismo';
				this.comission_five = 'Saúde, assistência e assuntos diversos';
				/* this.comission_six = 'Comissão de Desenvolvimento Econômico e Social'; */

			}else if(this.StoreAll.host == 'local'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'upanema'){

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}else if(this.StoreAll.host == 'angicos'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';
				/* this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
        this.comission_six = 'Comissão de Desenvolvimento Econômico e Social'; */

			}else if(this.StoreAll.host == 'bf'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'sbn'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'pureza'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'abapp'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'macaiba'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'veracruz'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'sm'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else if(this.StoreAll.host == 'sr'){

				this.comission_one = 'Comissão de Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças, Fiscalização e Controle';
				this.comission_three = 'Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades';
				this.comission_four = 'Comissão da Região central do Rio grande do norte';

			}else{

				this.comission_one = 'Comissão de Constituição, Justiça e Redação';
				this.comission_two = 'Comissão de Orçamento, Finanças e Contabilidade';
				this.comission_three = 'Comissão de Obras e Serviços Públicos';
				this.comission_four = 'Comissão de Saúde, Educação, Cultura, Lazer e Turismo';
				this.comission_five = 'Comissão de Planejamento, Uso, Ocupação e Parcelamento do Solo';
				this.comission_six = 'Comissão de Desenvolvimento Econômico e Social';

			}
		},
		async getParecer() {
			let parecer = {
				1: [], // education
				2: [], // farm
				3: [], // infra
				4: [], // health
				5: [], // human
				6: []  // justice
			};

			if(this.StoreAll.parecer_procurador_pdf == null){
				this.parecer_proc_status = false;
			}else{
				this.parecer_proc_status = true;
				this.parecer_proc = this.StoreAll.parecer_procurador_pdf;
			}

			await AxiosAPI
				.get('/parecer-comissao?document_id=' + this.StoreAll.document_details.id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					if (response.data.parecer.length <= 0) {
						this.parecer_on = false;
					} else {
						this.parecer_on = true;
					}
					for (let i = 0; i < response.data.parecer.length; i++) {
						const comission_id = response.data.parecer[i].comission_id;
						if (parecer[comission_id]) {
							parecer[comission_id].push(response.data.parecer[i]);
						}
					}

					this.parecer_education = parecer[1][0]?.parecer_pdf || null;
					this.parecer_farm = parecer[2][0]?.parecer_pdf || null;
					this.parecer_infra = parecer[3][0]?.parecer_pdf || null;
					this.parecer_health = parecer[4][0]?.parecer_pdf || null;
					this.parecer_human = parecer[5][0]?.parecer_pdf || null;
					this.parecer_justice = parecer[6][0]?.parecer_pdf || null;

					this.parecer_education_id = parecer[1][0]?.id || null;
					this.parecer_farm_id = parecer[2][0]?.id || null;
					this.parecer_infra_id = parecer[3][0]?.id || null;
					this.parecer_health_id = parecer[4][0]?.id || null;
					this.parecer_human_id = parecer[5][0]?.id || null;
					this.parecer_justice_id = parecer[6][0]?.id || null;

					if(this.parecer_education != null){this.parecer_education_status = true;}
					if(this.parecer_farm != null){this.parecer_farm_status = true;}
					if(this.parecer_infra != null){this.parecer_infra_status = true;}
					if(this.parecer_health != null){this.parecer_health_status = true;}
					if(this.parecer_human != null){this.parecer_human_status = true;}
					if(this.parecer_justice != null){this.parecer_justice_status = true;}
				})
				.catch((error) => {
					console.error(error);
				});
		},


		openModal() {
			this.$refs.viewDocument.openModal();
			this.parecer_farm_status = false,
			this.parecer_education_status = false,
			this.parecer_infra_status = false,
			this.parecer_health_status = false,
			this.parecer_human_status = false,
			this.parecer_justice_status = false,
			this.parecer_proc_status = false,
			this.parecer_education = null,
			this.parecer_proc = null,
			this.parecer_farm = null,
			this.parecer_infra = null,
			this.parecer_health = null,
			this.parecer_human = null,
			this.parecer_justice = null,
			this.Name_Comission();
			this.getParecer();
		},

		showParecer_Modal(value){
			if(value == 1){
				this.StoreAll.parecer_src = this.parecer_education;
				this.StoreAll.parecer_id = this.parecer_education_id;
			}
			else if(value == 2){
				this.StoreAll.parecer_src = this.parecer_farm;
				this.StoreAll.parecer_id = this.parecer_farm_id;
			}
			else if(value == 3){
				this.StoreAll.parecer_src = this.parecer_infra;
				this.StoreAll.parecer_id = this.parecer_infra_id;
			}
			else if(value == 4){
				this.StoreAll.parecer_src = this.parecer_health;
				this.StoreAll.parecer_id = this.parecer_health_id;
			}
			else if(value == 5){
				this.StoreAll.parecer_src = this.parecer_human;
				this.StoreAll.parecer_id = this.parecer_human_id;
			}
			else if(value == 6){
				this.StoreAll.parecer_src = this.parecer_justice;
				this.StoreAll.parecer_id = this.parecer_justice_id;
			}
			else if(value == 7){
				this.StoreAll.parecer_src = this.StoreAll.parecer_procurador_pdf;
				this.StoreAll.parecer_id = this.parecer_education_id;
			}
			this.$refs.Modal_Parecer.openModal();
		}

	}
};


</script>

<style></style>