<script>
import ModalBigger from './ModalBigger.vue';
import { AxiosAPI } from '@/axios';
import interact from 'interactjs';
import 'pdfjs-dist/web/pdf_viewer.css';
import preview_stamp from '../Modals/Modal_preview_Stamp.vue';
import { PDFDocument, rgb, scale, StandardFonts } from 'pdf-lib';
import { useMainStore } from '../../../stores/index';
import { useBusStore } from '../../../stores/index';
export default {
	components: {
		ModalBigger,
		preview_stamp,
	},
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			carimbo_locale: 0,
			status_stamp: 1,
			imageSrc: null,
			pdfonly: null,
			pdfBase64: null,
			pdfDocument: null,
			pageNumber: 1,
			pdfPage: null,
			position: null,
			space_aprove: 20,
			stamp: '',
			date_stamp: '',
			stamp_camara: 'Aprovado',
			stamp_prefecture: 'Recebido',
			stampType: 0
		};
	},

	mounted(){
		// eslint-disable-next-line quotes
		this.emitter.on("stamp_approve", (stamp_approve) => {
			this.Stamps_approve(stamp_approve);
		});

	},
	methods: {
		openModal() {
			const { voting_result_1, voting_result_2 } = this.StoreAll.document_details;

			if (voting_result_1 != null || voting_result_2 != null) {
				this.stampType = voting_result_2 != null ? voting_result_2 : voting_result_1;
			} else {
				this.stampType = 0;
			}
			this.$refs.viewDocument.openModal();
			this.status_stamp = 1;
			this.convertToBase64();
			this.Carimbo_img();
			this.hosts_stamp(1);
		},

		openModal2() {
			this.$refs.viewDocument.openModal();
			this.status_stamp = 2;
			this.convertToBase64();
			this.Carimbo_img();
			this.hosts_stamp(2);
		},

		openModal3() {
			this.$refs.viewDocument.openModal();
			this.status_stamp = 3;
			this.convertToBase64();
			this.Carimbo_img();
		},

		Carimbo_img(){this.imageSrc = require('../../../assets/img/brasoes/BrasaoCamara_ja.png');},

		async convertToBase64() {
			let ur = JSON.stringify({hiperlink: this.StoreAll.Dochost + this.StoreAll.document_src});
			await AxiosAPI.post('/get-base64', ur, {
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				}
			}).then((response) => {
				this.pdfBase64 = response.data.base64;
				this.loadPdf();
			});
		},

		hosts_stamp(value){
			if(value === 1){
				let stamp = '';
				if(this.StoreAll.host == 'teste'){
					stamp = 'CM PEDRO AVELINO';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'dev'){
					stamp = 'CM PEDRO AVELINO';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'jc'){
					stamp = 'CM JOÃO CÂMARA';
					this.space_aprove = 18;
					this.space_date = 30;
				}else if(this.StoreAll.host == 'bodo'){
					stamp = 'CM BODÓ';
					this.space_aprove = 0;
					this.space_date = 0;
				}else if(this.StoreAll.host == 'crv'){
					stamp = 'CM CRV';
					this.space_aprove = 0;
					this.space_date = 0;
				}else if(this.StoreAll.host == 'pedroavelino'){
					stamp = 'CM PEDRO AVELINO';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'pedrapreta'){
					stamp = 'CM PEDRA PRETA';
					this.space_aprove = 16;
					this.space_date = 28;
				}else if(this.StoreAll.host == 'campogrande'){
					stamp = 'CM CAMPO GRANDE';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'parazinho'){
					stamp = 'CM PARAZINHO';
					this.space_aprove = 10;
					this.space_date = 21;
				}else if(this.StoreAll.host == 'goianinha'){
					stamp = 'CM GOIANINHA';
					this.space_aprove = 10;
					this.space_date = 21;
				}else if(this.StoreAll.host == 'jucurutu'){
					stamp = 'CM JUCURUTU';
					this.space_aprove = 4;
					this.space_date = 15;
				}else if(this.StoreAll.host == 'odb'){
					stamp = 'CM OLHO-D`ÁGUA DO BORGES';
					this.space_aprove = 42;
					this.space_date = 51;
				}else if(this.StoreAll.host == 'lp'){
					stamp = 'CM LAGOA DE PEDRAS';
					this.space_aprove = 36;
					this.space_date = 46;
				}else if(this.StoreAll.host == 'sr'){
					stamp = 'CM SÃO RAFAEL';
					this.space_aprove = 11;
					this.space_date = 22;
				}else if(this.StoreAll.host == 'local'){
					stamp = 'CM OLHO-D`ÁGUA DO BORGES';
					this.space_aprove = 37;
					this.space_date = 47;
				}else if(this.StoreAll.host == 'jandaira'){
					stamp = 'CM JANDAÍRA';
					this.space_aprove = 4;
					this.space_date = 15;
				}else if(this.StoreAll.host == 'triunfo'){
					stamp = 'CM TRIUNFO PORTIGUAR';
					this.space_aprove = 39;
					this.space_date = 49;
				}else if(this.StoreAll.host == 'upanema'){
					stamp = 'CM UPANEMA';
					this.space_aprove = 3;
					this.space_date = 13;
				}else if(this.StoreAll.host == 'ja'){
					stamp = 'CM JARDIM DE ANGICOS';
					this.space_aprove = 37;
					this.space_date = 47;
				}else if(this.StoreAll.host == 'angicos'){
					stamp = 'CM ANGICOS';
					this.space_aprove = 0;
					this.space_date = 9;
				}else if(this.StoreAll.host == 'sm'){
					stamp = 'CM SERRA DO MEL';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'bf'){
					stamp = 'CM BAIA FORMOSA';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'sbn'){
					stamp = 'CM SÃO BENTO DO NORTE';
					this.space_aprove = 37;
					this.space_date = 47;
				}else if(this.StoreAll.host == 'pureza'){
					stamp = 'CM PUREZA';
					this.space_aprove = 0;
					this.space_date = 9;
				}else if(this.StoreAll.host == 'abapp'){
					stamp = 'CM AFONSO BEZERRA';
					this.space_aprove = 24;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'macaiba'){
					stamp = 'CM MACAÍBA';
					this.space_aprove = 1;
					this.space_date = 10;
				}else if(this.StoreAll.host == 'veracruz'){
					stamp = 'CM VERA CRUZ';
					this.space_aprove = 4;
					this.space_date = 15;
				}

				const meuArray = this.StoreAll.document_details.sessions;
				const ultimaLinha = meuArray[meuArray.length - 1];

				let date_doc = ultimaLinha.datetime_start;
				let date_time = new Date(date_doc);
				let dia = date_time.getDate();
				let mes = date_time.getMonth() + 1;
				let ano = date_time.getFullYear();
				let date_stamp = '';
				if(mes <= 9){
					date_stamp = dia + '/' + '0'+mes + '/' + ano;
				}else{
					date_stamp = dia + '/' + mes + '/' + ano;
				}

				this.stamp = stamp;
				this.date_stamp = date_stamp;
			}else{
				let stamp = '';
				if(this.StoreAll.host == 'teste'){
					stamp = 'PM JOÃO CÂMARA';
					this.space_aprove = 22;
					this.space_date = 28;
				}else if(this.StoreAll.host == 'dev'){
					stamp = 'PM JOÃO CÂMARA';
					this.space_aprove = 22;
					this.space_date = 28;
				}else if(this.StoreAll.host == 'jc'){
					stamp = 'PM JOÃO CÂMARA';
					this.space_aprove = 22;
					this.space_date = 28;
				}else if(this.StoreAll.host == 'bodo'){
					stamp = 'PM BODÓ';
					this.space_aprove = 0;
					this.space_date = 0;
				}else if(this.StoreAll.host == 'crv'){
					stamp = 'PM CRV';
					this.space_aprove = 0;
					this.space_date = 0;
				}else if(this.StoreAll.host == 'pedroavelino'){
					stamp = 'PM PEDRO AVELINO';
					this.space_aprove = 28;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'pedrapreta'){
					stamp = 'PM PEDRA PRETA';
					this.space_aprove = 18;
					this.space_date = 26;
				}else if(this.StoreAll.host == 'campogrande'){
					stamp = 'PM CAMPO GRANDE';
					this.space_aprove = 26;
					this.space_date = 34;
				}else if(this.StoreAll.host == 'parazinho'){
					stamp = 'PM PARAZINHO';
					this.space_aprove = 12;
					this.space_date = 19;
				}else if(this.StoreAll.host == 'goianinha'){
					stamp = 'PM GOIANINHA';
					this.space_aprove = 12;
					this.space_date = 19;
				}else if(this.StoreAll.host == 'jucurutu'){
					stamp = 'PM JUCURUTU';
					this.space_aprove = 8;
					this.space_date = 14;
				}else if(this.StoreAll.host == 'odb'){
					stamp = 'PM OLHO-D`ÁGUA DO BORGES';
					this.space_aprove = 42;
					this.space_date = 50;
				}else if(this.StoreAll.host == 'lp'){
					stamp = 'PM LAGOA DE PEDRAS';
					this.space_aprove = 37;
					this.space_date = 45;
				}else if(this.StoreAll.host == 'sr'){
					stamp = 'PM SÃO RAFAEL';
					this.space_aprove = 13;
					this.space_date = 20;
				}else if(this.StoreAll.host == 'local'){
					stamp = 'PM ANGICOS';
					this.space_aprove = 4;
					this.space_date = 11;
				}else if(this.StoreAll.host == 'jandaira'){
					stamp = 'PM JANDAÍRA';
					this.space_aprove = 8;
					this.space_date = 14;
				}else if(this.StoreAll.host == 'triunfo'){
					stamp = 'PM TRIUNFO PORTIGUAR';
					this.space_aprove = 40;
					this.space_date = 48;
				}else if(this.StoreAll.host == 'upanema'){
					stamp = 'PM UPANEMA';
					this.space_aprove = 8;
					this.space_date = 15;
				}else if(this.StoreAll.host == 'ja'){
					stamp = 'PM JARDIM DE ANGICOS';
					this.space_aprove = 39;
					this.space_date = 47;
				}else if(this.StoreAll.host == 'angicos'){
					stamp = 'PM ANGICOS';
					this.space_aprove = 4;
					this.space_date = 11;
				}else if(this.StoreAll.host == 'sm'){
					stamp = 'PM SERRA DO MEL';
					this.space_aprove = 26;
					this.space_date = 34;
				}else if(this.StoreAll.host == 'bf'){
					stamp = 'PM BAIA FORMOSA';
					this.space_aprove = 26;
					this.space_date = 34;
				}else if(this.StoreAll.host == 'sbn'){
					stamp = 'PM SÃO BENTO DO NORTE';
					this.space_aprove = 39;
					this.space_date = 47;
				}else if(this.StoreAll.host == 'pureza'){
					stamp = 'PM PUREZA';
					this.space_aprove = 3;
					this.space_date = 11;
				}else if(this.StoreAll.host == 'abapp'){
					stamp = 'PM AFONSO BEZERRA';
					this.space_aprove = 28;
					this.space_date = 36;
				}else if(this.StoreAll.host == 'macaiba'){
					stamp = 'PM MACAÍBA';
					this.space_aprove = 3;
					this.space_date = 11;
				}else if(this.StoreAll.host == 'veracruz'){
					stamp = 'PM VERA CRUZ';
					this.space_aprove = 8;
					this.space_date = 14;
				}

				let date_time = new Date();
				let dia = date_time.getDate();
				let mes = date_time.getMonth() + 1;
				let ano = date_time.getFullYear();
				let date_stamp = '';
				if(mes <= 9){
					date_stamp = dia + '/' + '0'+mes + '/' + ano;
				}else{
					date_stamp = dia + '/' + mes + '/' + ano;
				}

				this.stamp = stamp;
				this.date_stamp = date_stamp;
			}
		},

		async loadPdf() {
			let pdfjs;
			// eslint-disable-next-line quotes
			pdfjs = await import("../../../../node_modules/pdfjs-dist/build/pdf");
			// eslint-disable-next-line quotes
			const pdfjsWorker = await import("../../../../node_modules/pdfjs-dist/build/pdf.worker.entry");
			pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


			if (this.pdfDocument) {
				this.pdfDocument.destroy();
			}

			const loadingTask = pdfjs.getDocument({ data: atob(this.pdfBase64) });

			try {
				this.pdfDocument = await loadingTask.promise;
				this.renderPage();
			} catch (error) {
				console.error('Erro ao carregar o PDF:', error);
			}
		},
		async renderPage() {
			this.pdfDocument.getPage(this.pageNumber).then((page) => {
				this.pdfPage = page;
				const canvas = this.$refs.pdfCanvas;
				const context = canvas.getContext('2d');

				const viewport = page.getViewport({ scale: 1.5 });

				canvas.width = viewport.width;
				canvas.height = viewport.height;

				page.render({
					canvasContext: context,
					viewport: viewport,
				});
			});
			const base64Pdf = this.pdfBase64;

			const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer;

			const pdfDoc = await PDFDocument.load(existingPdfBytes);

			const pages = pdfDoc.getPages();
			const firstPage = pages[0];

			const { width, height } = firstPage.getSize();
			let dat  = { width, height };
			this.Intera(dat);
		},

		async Stamps_approve(value){
			if(value === 1){
				const base64Pdf = this.pdfBase64;

				const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer;

				const pdfDoc = await PDFDocument.load(existingPdfBytes);

				const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

				const pages = pdfDoc.getPages();
				const firstPage = pages[0];

				const { width, height } = firstPage.getSize();
				// eslint-disable-next-line quotes
				let resultString = '';
				let resultColor = rgb(0, 0, 0);

				if (this.stampType === 0) {
					resultString = 'SEM RESULTADO';
					resultColor = rgb(0, 0, 0);
				}

				if (this.stampType === 1) {
					resultString = 'APROVADO';
					resultColor = rgb(0, 0.53, 0.71);
				}

				if (this.stampType === 2) {
					resultString = 'REPROVADO';
					resultColor = rgb(1, 0, 0);
				}
				const textPart1 = `${this.stamp}`;const textPart2 = resultString;const textPart3 = this.date_stamp;

				const textSize1 = 14;
				const textSize2 = 16;
				const textSize3 = 14;

				const position1 = { x: this.position.x, y: this.position.y };
				const position2 = { x: this.position.x, y: this.position.y };
				const position3 = { x: this.position.x, y: this.position.y };

				firstPage.drawText(textPart1, {
					x: position1.x,
					y: position1.y,
					size: textSize1,
					font: timesRomanFont,
					color: resultColor,
				});

				firstPage.drawText(textPart2, {
					x: position2.x + this.space_aprove,
					y: position2.y - 20,
					size: textSize2,
					font: timesRomanFont,
					color: resultColor,
				});

				firstPage.drawText(textPart3, {
					x: position3.x + this.space_date,
					y: position3.y - 40,
					size: textSize3,
					font: timesRomanFont,
					color: resultColor,
				});
				const pdfBytes = await pdfDoc.save();

				const blob = new Blob([pdfBytes], { type: 'application/pdf' });

				const formData = new FormData();

				formData.append('document_id', this.StoreAll.document_details.id);
				formData.append('attachment', blob);
				AxiosAPI
					.post('/documents/stamp-camara', formData,{
						headers: {
							'Content-Type': 'application/json',
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						}
					}).then(() => {
						this.$refs.preview_stamp.closeModal_Loading();
						this.$refs.preview_stamp.closeModal();
						this.$refs.viewDocument.closeModal();
						this.showSuccess_carimbo();
						this.Bus.docSendSession = true;
					});
			}else{

				const base64Pdf = this.pdfBase64;

				const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer;

				const pdfDoc = await PDFDocument.load(existingPdfBytes);

				const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

				const pages = pdfDoc.getPages();
				const firstPage = pages[0];

				const { width, height } = firstPage.getSize();
				// eslint-disable-next-line quotes
				const textPart1 = `${this.stamp}`;const textPart2 = `RECEBIDO`;const textPart3 = this.date_stamp;

				const textSize1 = 14;
				const textSize2 = 16;
				const textSize3 = 14;

				const position1 = { x: this.position.x, y: this.position.y };
				const position2 = { x: this.position.x, y: this.position.y };
				const position3 = { x: this.position.x, y: this.position.y };

				firstPage.drawText(textPart1, {
					x: position1.x,
					y: position1.y,
					size: textSize1,
					font: timesRomanFont,
					color: rgb(0, 0.53, 0.71),
				});

				firstPage.drawText(textPart2, {
					x: position2.x + this.space_aprove,
					y: position2.y - 20,
					size: textSize2,
					font: timesRomanFont,
					color: rgb(0, 0.53, 0.71),
				});

				firstPage.drawText(textPart3, {
					x: position3.x + this.space_date,
					y: position3.y - 40,
					size: textSize3,
					font: timesRomanFont,
					color: rgb(0, 0.53, 0.71),
				});

				const pdfBytes = await pdfDoc.save();

				const blob = new Blob([pdfBytes], { type: 'application/pdf' });

				const formData = new FormData();

				formData.append('document_id', this.StoreAll.document_details.id);
				formData.append('attachment', blob);

				AxiosAPI
					.post('/documents/stamp-prefeitura', formData,{
						headers: {
							'Content-Type': 'application/json',
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						}
					}).then(() => {
						this.$refs.preview_stamp.closeModal_Loading();
						this.$refs.preview_stamp.closeModal();
						this.$refs.viewDocument.closeModal();
						this.showSuccess_carimbo();
						this.emitter.emit('carimbo_prefeitura', 1);
					});

			}
		},

		async Stamp_Approve_preview() {

			const base64Pdf = this.pdfBase64;

			const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer;

			const pdfDoc = await PDFDocument.load(existingPdfBytes);

			const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

			const pages = pdfDoc.getPages();
			const firstPage = pages[0];

			const { width, height } = firstPage.getSize();

			let resultString = '';
			let resultColor = rgb(0, 0, 0);

			if (this.stampType === 0) {
				resultString = 'SEM RESULTADO';
				resultColor = rgb(0, 0, 0);
			}

			if (this.stampType === 1) {
				resultString = 'APROVADO';
				resultColor = rgb(0, 0.53, 0.71);
			}

			if (this.stampType === 2) {
				resultString = 'REPROVADO';
				resultColor = rgb(1, 0, 0);
			}
			// eslint-disable-next-line quotes
			const textPart1 = `${this.stamp}`;const textPart2 = resultString;const textPart3 = this.date_stamp;
			const textSize1 = 14;
			const textSize2 = 16;
			const textSize3 = 14;

			const position1 = { x: this.position.x, y: this.position.y };
			const position2 = { x: this.position.x, y: this.position.y };
			const position3 = { x: this.position.x, y: this.position.y };

			firstPage.drawText(textPart1, {
				x: position1.x,
				y: position1.y,
				size: textSize1,
				font: timesRomanFont,
				color: resultColor,
			});

			firstPage.drawText(textPart2, {
				x: position2.x + this.space_aprove,
				y: position2.y - 20,
				size: textSize2,
				font: timesRomanFont,
				color: resultColor,
			});

			firstPage.drawText(textPart3, {
				x: position3.x + this.space_date,
				y: position3.y - 40,
				size: textSize3,
				font: timesRomanFont,
				color: resultColor,
			});
			const pdfBytes = await pdfDoc.save();

			const blob = new Blob([pdfBytes], { type: 'application/pdf' });


			this.$refs.preview_stamp.openModal(blob);
		},

		async Stamp_prefeitura() {
			const base64Pdf = this.pdfBase64;

			const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer;

			const pdfDoc = await PDFDocument.load(existingPdfBytes);

			const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
;
			const pages = pdfDoc.getPages();
			const firstPage = pages[0];

			const { width, height } = firstPage.getSize();
			/*  const text = `${this.stamp}\n${' '.repeat((this.space_aprove - 'RECEBIDO'.length) / 2)}RECEBIDO\n${' '.repeat((this.space_date - 'RECEBIDO'.length) / 2)}${this.date_stamp}`
    const textSize = 12
    firstPage.drawText(text, {
      x: this.position.x,
      y: this.position.y,
      size: textSize,
      font: timesRomanFont,
      color: rgb(0, 0.53, 0.71),
    }) */
			// eslint-disable-next-line quotes
			const textPart1 = `${this.stamp}`;const textPart2 = `RECEBIDO`;const textPart3 = this.date_stamp;

			const textSize1 = 14;
			const textSize2 = 16;
			const textSize3 = 14;

			const position1 = { x: this.position.x, y: this.position.y };
			const position2 = { x: this.position.x, y: this.position.y };
			const position3 = { x: this.position.x, y: this.position.y };

			firstPage.drawText(textPart1, {
				x: position1.x,
				y: position1.y,
				size: textSize1,
				font: timesRomanFont,
				color: rgb(0, 0.53, 0.71),
			});

			firstPage.drawText(textPart2, {
				x: position2.x + this.space_aprove,
				y: position2.y - 20,
				size: textSize2,
				font: timesRomanFont,
				color: rgb(0, 0.53, 0.71),
			});

			firstPage.drawText(textPart3, {
				x: position3.x + this.space_date,
				y: position3.y - 40,
				size: textSize3,
				font: timesRomanFont,
				color: rgb(0, 0.53, 0.71),
			});

			const pdfBytes = await pdfDoc.save();

			const blob = new Blob([pdfBytes], { type: 'application/pdf' });

			this.$refs.preview_stamp.openModal(blob);


		},

		Intera(value) {

			const position = { x: 0, y: 0 };
			const now_p = {x:0, y:0};
			this.position = now_p;

			interact('.draggable').draggable({
				listeners: {
					start (event) {
						console.log(event.type, event.target, position.x, position.y);
					},
					move (event) {
						position.x += event.dx;
						position.y += event.dy;

						event.target.style.transform =
              `translate(${position.x}px, ${position.y}px)`;
					},
					end (event){
						position.x += event.dx;
						position.y += event.dy;

						let div = document.getElementById('ww');
						let largura = div.clientWidth;

						let scaledX = (position.x / largura) * value.width + 30;

						now_p.x = scaledX;

						let altura = div.clientHeight;
						let scaledy = (position.y / altura) * value.height + 10; //VER SE ISSO TA OK!

						let y = scaledy;

						let maxvalue = value.height;

						let yInvertido = maxvalue - y;

						now_p.y = yInvertido;


						event.target.style.transform =
              `translate(${position.x}px, ${position.y}px)`;
					},
				},
				modifiers: [
					interact.modifiers.restrictRect({
						restriction: 'parent', // Restringir o movimento ao elemento pai
						endOnly: true, // Restringir apenas quando o movimento terminar
						top: 0, // Limite superior (não restrito no eixo Y)
						left: 0, // Limite esquerdo
						right: 0, // Limite direito
						bottom: 0, // Limite inferior (não restrito no eixo Y)
					}),
					interact.modifiers.snap({
						targets: [
							function () {
								// Escala de 0 a 1000px mapeada para 0 a 500 do elemento arrastável
								/* console.log(x)
              var scaledX = (x / 918) * value;
              console.log(scaledX) */
							}
						],
						range: Infinity, // Permite arrastar indefinidamente
						relativePoints: [{ x: 0, y: 0 }],
						endOnly: true, // Somente ajusta a posição no final do arraste
					}),
				],
			});
		},

		carimbo_approved_lote() {

			let dados = this.StoreAll.MultiSelectDocs;
			let documents_id = [];
			for (let i = 0; i < dados.length; i++) {
				documents_id.push(dados[i].id);
			}
			let carimbo = JSON.stringify({
				document_id: documents_id,
				session_id: this.StoreAll.session.id,
				posicao_carimbo: this.carimbo_locale,
			});

			AxiosAPI.post('/documents/stamp-approved', carimbo, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${this.StoreAll.accessToken}`,
				},
			}).then((resp) => {
				console.log(resp);
				this.$refs.viewDocument.closeModal();
				this.showSuccess_carimbo();
				this.Bus.docSendSession = true;
			});
		},

		showSuccess_carimbo() {
			this.$toast.add({ severity: 'success', summary: 'Documento carimbado com sucesso', detail: 'O documento foi carimbado sucesso', life: 3000 });
		},
	}
};


</script>
<template>
  <div>
	<preview_stamp ref="preview_stamp"></preview_stamp>
      <ModalBigger ref="viewDocument"  style="z-index: 1000;">
          <template #header>
              <strong><span> Onde deseja carimbar o documento ? </span> </strong>

              <div class="flex-start">
                  <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
          </template>

          <template #body>

            <canvas ref="pdfCanvas" style="z-index: 999;"  id="ww"></canvas>
            <div v-if="this.StoreAll.user.user_category_id === 1" class="draggable text-center" :class="{'draggableNO': stampType === 0, 'draggableApro': stampType === 1, 'draggableRep': stampType === 2}" id="draggable" style="z-index: 1000;">
				<span>
				<b> {{ stamp }}
					<br/>
				<span v-if="stampType === 1"> APROVADO</span>
				<span v-if="stampType === 2"> REPROVADO</span>
				<span v-if="stampType === 0"> SEM RESULTADO</span>
					<br/>
					{{ date_stamp }}
				</b>
				</span>
			</div>
            <div v-else class="draggable text-center" id="draggable" style="z-index: 1000;"> <span> <b> {{ stamp }} <br/> RECEBIDO <br/> {{ date_stamp }} </b></span></div>

          </template>

          <template #footer>
            <div v-if="status_stamp == 1" class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
              Voltar
          </button>
          <button type="button" class="btn btn-primary bg-primary mr-3" @click="Stamp_Approve_preview()">
              Continuar
          </button>
        </div>

        <div v-if="status_stamp == 2" class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
              Voltar
          </button>
          <button type="button" class="btn btn-primary bg-primary mr-3" @click="Stamp_prefeitura(1)">
            Contiunar
          </button>
        </div>

        <div v-if="status_stamp == 3" class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
              Voltar
          </button>
          <button type="button" class="btn btn-primary bg-success" @click="carimbo_approved_lote()">
              Carimbar
          </button>
        </div>
          </template>
      </ModalBigger>

  </div>
</template>
<style>
.draggable {
position: absolute;
border: 1px solid black;
width: 32%;
font-size: 1.3em;
font-family: Arial, Helvetica, sans-serif;
min-height: 8px;
touch-action: none;
user-select: none;
opacity: 0.7;
}
.draggableApro{
	color: rgb(0, 38, 255);
}
.draggableRep{
	color: rgb(255, 0, 0);
}
.draggableNO{
	color: rgb(0, 0, 0);
}
</style>