<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBig.vue';
export default {
	components: {
		ModalBigger,
	},

	data() {
		return {
			signer_status: null,
			prefix_c: '',
			SignerDocs: [],
			key: '',
			documento_src: '',
			storage_name: '',
			category_id: '',
			document_id: 0,
			session_id: 'realizadass',
			selected_products: null,
			key: null,
			status_user_sign: false
		};
	},

	methods: {
		openModal() {
			this.signer_status = 1;
			this.status_user_sign = false;
			this.pref(1);
			this.$refs.viewDocument.openModal();
		},

		openModal_lote() {
			this.signer_status = 2;
			this.status_user_sign = false;
			this.pref(2);
			this.$refs.viewDocument.openModal();
		},

		async pref(value){
			let prefix = '';
			if(this.$pinia.state.value.main.host == 'dev'){
				prefix = 'dev';
			}else if(this.$pinia.state.value.main.host == 'teste'){
				prefix = 'teste';
			}else if(this.$pinia.state.value.main.host == 'jc'){
				prefix = 'jc';
			}else if(this.$pinia.state.value.main.host == 'bodo'){
				prefix = 'bd';
			}else if(this.$pinia.state.value.main.host == 'pedroavelino'){
				prefix = 'pa';
			}else if(this.$pinia.state.value.main.host == 'pedrapreta'){
				prefix = 'pp';
			}else if(this.$pinia.state.value.main.host == 'campogrande'){
				prefix = 'cg';
			}else if(this.$pinia.state.value.main.host == 'parazinho'){
				prefix = 'parazinho';
			}else if(this.$pinia.state.value.main.host == 'local'){
				prefix = 'dev';
			}else if(this.$pinia.state.value.main.host == 'jandaira'){
				prefix = 'jd';
			}else if(this.$pinia.state.value.main.host == 'triunfo'){
				prefix = 'tp';
			}else if(this.$pinia.state.value.main.host == 'upanema'){
				prefix = 'upanema';
			}else if(this.$pinia.state.value.main.host == 'ja'){
				prefix = 'ja';
			}else if(this.$pinia.state.value.main.host == 'angicos'){
				prefix = 'angicos';
			}else if(this.$pinia.state.value.main.host == 'goianinha'){
				prefix = 'goianinha';
			}else if(this.$pinia.state.value.main.host == 'crv'){
				prefix = 'crv';
			}else if(this.$pinia.state.value.main.host == 'jucurutu'){
				prefix = 'jucurutu';
			}else if(this.$pinia.state.value.main.host == 'odb'){
				prefix = 'odb';
			}else if(this.$pinia.state.value.main.host == 'sm'){
				prefix = 'sm';
			}else if(this.$pinia.state.value.main.host == 'lp'){
				prefix = 'lp';
			}else if(this.$pinia.state.value.main.host == 'sr'){
				prefix = 'sr';
			}else if(this.$pinia.state.value.main.host == 'bf'){
				prefix = 'bf';
			}else if(this.$pinia.state.value.main.host == 'sbn'){
				prefix = 'sbn';
			}else if(this.$pinia.state.value.main.host == 'pureza'){
				prefix = 'pureza';
			}else if(this.$pinia.state.value.main.host == 'abapp'){
				prefix = 'abapp';
			}else if(this.$pinia.state.value.main.host == 'macaiba'){
				prefix = 'macaiba';
			}else if(this.$pinia.state.value.main.host == 'veracruz'){
				prefix = 'veracruz';
			}

			this.prefix_c = prefix;
			if(value == 1){
				this.key = this.$pinia.state.value.main.document_details.request_signature_key
				var verificarElemento = setInterval(() => {
					if (document.getElementById('signer') !== null) {
						clearInterval(verificarElemento);
						this.signer();
						this.Document_att();
					}
				}, 500); //Nessa função, enquanto signer for null a função não é executada.
			}else{
				var verificarElemento = setInterval(() => {
					if (document.getElementById('signer_lote') !== null) {
						clearInterval(verificarElemento);
						this.signer_lote();
						this.Document_att();
					}
				}, 500);
			}; 
      
		},

		Document_att(){
			setTimeout(()=>{ this.Document_att()}, 4000);
			if(localStorage.getItem('ClickSing') == 'Ok'){
				this.emitter.emit("Assinado", 1);
				this.showCenter();
				localStorage.removeItem('ClickSing')
			}else{
				return
			}
		},

		async signer(){
			let url_base = 'https://app.clicksign.com/sign/'+this.key;

			AxiosAPI.get('/clicksing/verify?link='+url_base,
				{
					headers: {
						Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken,
					},
				}
			).then((resp)=>{
				this.status_user_sign = resp.data.redireciona;

				if(resp.data.redireciona == false){

					let camara = this.prefix_c;

					function Clicksign(u){"use strict";var n,i,c="https://app.clicksign.com",s=window.location.protocol+"//"+window.location.host,r={},l=function(t){var e="/sign/"+u,f="?embedded=true&origin="+this.origin,h=this.endpoint+e+f;return i=document.getElementById(t),n=document.createElement("iframe"),n.setAttribute("src",h),n.setAttribute("style","width: 100%; height: 100%;"),n.setAttribute("allow","camera;geolocation;fullscreen;gyroscope;accelerometer;magnetometer"),window.addEventListener("message",a),i.appendChild(n)},m=function(t){return t.name||t},d=function(t,e){return r[t]||(r[t]=[]),r[t].push(e)},o=function(t){(r[m(t)]||[]).forEach(function(e){e(t.data)})},a=function(t){o(t.data)},g=function(){return n&&(i.removeChild(n),i=null,n=null,window.removeEventListener("message",a)),!0};return{endpoint:c,origin:s,mount:l,unmount:g,on:d,trigger:o}}globalThis.Clicksign=Clicksign;
  					let widget = new Clicksign(this.key);
					widget.endpoint = 'https://app.clicksign.com/';
					widget.origin = 'https://'+camara+'.smartlegis.net.br/';
					console.log(widget);
					widget.mount('signer');

					widget.on('loaded', function(ev) {
						console.log('loaded!');
					});

					widget.on('signed', function(ev) {
						console.log('signed!');

					});

					widget.on('resized', function (height) {
						console.log('resized!');
						document.getElementById('signer').style.height = height + 'px';
					});

				}else{
					this.status_user_sign = true;
					console.log('já assinado');
				}
			});

		},

		signer_lote(){
			let camara_lote = this.prefix_c

			const documents_id = []
			for (let i = 0; i < this.$pinia.state.value.main.MultiSelectDocs.length; i++) {
				documents_id.push(this.$pinia.state.value.main.MultiSelectDocs[i].id)
    
			}

			const dados = {
				user_id: this.$pinia.state.value.main.user.id,
				documents: documents_id
			}

			AxiosAPI.post('/clicksign/batch', dados,
				{
					headers: {
						authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
					},
				}
			).then((resp)=>{
				const key_doc = resp.data.data.batch.request_signature_key

				let url_base = "https://app.clicksign.com/sign/"+key_doc
            
				AxiosAPI.get('/clicksing/verify?link='+url_base,
					{
						headers: {
							Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
						},
					}
				).then((resp)=>{
					this.status_user_sign = resp.data.redireciona

					if(resp.data.redireciona == false){
                  
						function Clicksign(u){"use strict";var n,i,c="https://app.clicksign.com",s=window.location.protocol+"//"+window.location.host,r={},l=function(t){var e="/sign/"+u,f="?embedded=true&origin="+this.origin,h=this.endpoint+e+f;return i=document.getElementById(t),n=document.createElement("iframe"),n.setAttribute("src",h),n.setAttribute("style","width: 100%; height: 100%;"),n.setAttribute("allow","camera;geolocation;fullscreen;gyroscope;accelerometer;magnetometer"),window.addEventListener("message",a),i.appendChild(n)},m=function(t){return t.name||t},d=function(t,e){return r[t]||(r[t]=[]),r[t].push(e)},o=function(t){(r[m(t)]||[]).forEach(function(e){e(t.data)})},a=function(t){o(t.data)},g=function(){return n&&(i.removeChild(n),i=null,n=null,window.removeEventListener("message",a)),!0};return{endpoint:c,origin:s,mount:l,unmount:g,on:d,trigger:o}}globalThis.Clicksign=Clicksign;
  					
						var widget = new Clicksign(key_doc);
						widget.endpoint = "https://app.clicksign.com";
						widget.origin = 'https://'+camara_lote+'.smartlegis.net.br/';

						widget.mount("signer_lote");
						widget.on('loaded', function(ev) {})
						widget.on('signed', function(ev) {})
                    
						widget.on("resized", function (height) {
							document.getElementById("signer_doc_lote").style.height = height + "px";
						});

					}else{
						this.status_user_sign = true
					}
				});
			});        
		},  

		removes(){ localStorage.removeItem('view'); localStorage.removeItem('documento_session_rec');localStorage.removeItem('documento_state_rec');localStorage.removeItem('documento_id_rec');localStorage.removeItem('documento_name_rec'); localStorage.removeItem('documento_src_rec'); this.documento_name_recs = 'Carregando...', this.documento_src_recs = ''},
      

		showCenter() {
			this.$toast.add({severity: 'info', summary: 'Documento assinado', detail: "Agora aguarde alguns segundo e seu documento será atualizado, você pode acompanhar em Meus Documentos enviados", group: 'tl', life: 8000});
		},
	}
}
</script>

<template>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1000;">
            <template #header>
              <div class="d-flex justify-content-between">
                <strong><span> Assinatura de documento </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
            </template>

            <template #body>

                <div v-if="signer_status == 1" class="d-flex justify-content-center">
                  <div id="signer" style='width: 100%;height: 700px'>
                    <div v-if="status_user_sign == true">
                      <div class="d-flex justify-content-center">
                        <img src="../../../assets/img/SmartLogo.png">
                      </div>
                      <div class="text-center">
                        <h2>Ops... Talvez Esse documento já tenha sido assinado!</h2>
                        <h4>Se você não assinou e está vendo essa mensagem, entre em contato com o administrador!</h4>
                      </div>
                    </div>
                    
                  </div>
                </div>

               <div v-if="signer_status == 2" class="d-flex justify-content-center">
                        <div id="signer_lote" style='width: 100%;height: 700px'>
                          <div v-if="status_user_sign == true">
                          <div class="d-flex justify-content-center">
                            <img src="../../../assets/img/SmartLogo.png">
                          </div>
                          <div class="text-center">
                            <h2>Ops... Talvez Esse documento já tenha sido assinado!</h2>
                            <h4>Se você não assinou e está vendo essa mensagem, entre em contato com o administrador!</h4>
                          </div>
                        </div>
                        </div>
                      </div> 

            </template>

            <template #footer>
            
            </template>
        </ModalBigger>

    </div>
</template>   
<style></style>