<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
export default {
	name: 'TvVote',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			category_doc: '',
			autor: '',
			doc_name_reduz: '',
			voted: true,
			users: [],
			us: [],
			User_Tot: [],
			document: [],
			session: {
				name: 'Carregando Informações...',
				id: 9999999,
			},
			user_presidente: [],
			quorum_users: [],
			presentes: 0,
			ausentes: 0,
			total: 0,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			Favor: 0,
			Contra: 0,
			Absten: 0,
			TotalVote: 0,
			doc_name: 'Aguarde...',
			vote_att: false,
			Loading: true,
		};
	},
	watch: {
		vote_att: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.votedstart();
				this.vote_att = false;
			}
		},
	},

	created () {
		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);

				this.StoreAll.socket.on('9', (e) => {
					this.vote_att = true;
				});

			}}, 500);
	},
 
	mounted() {
		this.Get_Quorum_Socket_Tv_vote(); 
		this.Session_timer();
		this.Hora();   
		setInterval(this.Hora, 1000);
	},

	methods: {
		async Get_Quorum_Socket_Tv_vote() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.GetDocument();
			this.get_expediente();
			this.Dat_doc();
		},

		async get_expediente() {
			try {
				const response = await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});
        
				this.users = response.data.filter(user => {
					const excludedStatusUser = [2, 3];
					return !excludedStatusUser.includes(user.status_user) && user.user_category_id !== 3;
				});
        
				this.users2 = response.data.filter(user => user.user_category_id === 3);
				this.user_presidente = this.users2;
        
				this.Quorum_atual_vote();
			} catch (error) {
				console.log(error);
			}
		},

		async Quorum_atual_vote() {
			await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				this.quorum_users = [];
				let dummy_presentes = 0;
				let dummy_presentess = 0;
				let user_P = [];
				let user_A = [];
				let user_T = [];
        
				for (let i = 0; i < this.users.length; i++) {
					this.users[i][1] = 'A';
				}

				for (let i = 0; i < response.data.data.length; i++) {
					for (let j = 0; j < this.users.length; j++) {
						if (this.users[j].name == response.data.data[i].user.name) {
							this.users[j][1] = 'P';
							dummy_presentes = dummy_presentes + 1;
							continue;
						}
					}
				}
				for (let i = 0; i < this.user_presidente.length; i++) {
					this.user_presidente[i][1] = 'A';
				}

				for (let i = 0; i < response.data.data.length; i++) {
					for (let j = 0; j < this.user_presidente.length; j++) {
						if (
							this.user_presidente[j].name == response.data.data[i].user.name
						) {
							this.user_presidente[j][1] = 'P';
							dummy_presentess = dummy_presentess + 1;
							continue;
						}
					}
				}

				this.total = this.users.length + this.user_presidente.length;
				this.presentes = dummy_presentes + dummy_presentess;
				this.ausentes = this.total - dummy_presentes - dummy_presentess;
				this.us = this.users;
				this.isQuorumLoaded = true;

				for (let t = 0; t < this.us.length; t++) {
					this.us[t][2] = 'N';
				}
				for (let t = 0; t < this.user_presidente.length; t++) {
					this.user_presidente[t][2] = 'N';
				}

				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i][1] == 'P') {
						user_P.push(this.users[i]);
						user_T.push(this.users[i]);
					}
				}
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i][1] == 'A') {
						user_A.push(this.users[i]);
						user_T.push(this.users[i]);
					}
				}
				this.User_Tot = user_T;
				this.StoreAll.votacon = true;
				this.Loading = false;
				this.votedstart();
			});


		}, 

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}else{
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},


		Dat_doc() {
			let Co_user = [];

			for (let i = 0; i < this.StoreAll.document.authors.length; i++) {
				Co_user.push(this.StoreAll.document.authors[i].nickname);
			}

			let result = Co_user.join(', ');

			this.category_doc = this.StoreAll.document.document_category.name;
			this.autor = result;
			this.doc_name = this.StoreAll.document.name;
		},
 
		async votedstart() {
			if (this.StoreAll.document == null) {
				return;
			} else {
				await AxiosAPI.get(
					'/votes?document_id=' +
          this.StoreAll.document.id +
          '&session_id=' +
          this.StoreAll.session.id,
					{
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((response) => {
					let favor = 0;
					let contra = 0;
					let absten = 0;
          
					this.votados = response.data.data;
					for (let t = 0; t < this.votados.length; t++) {
						for (let j = 0; j < this.us.length; j++) {
							if (this.us[j].name == this.votados[t].user.name) {
								if (this.votados[t].vote_category.id == 1) {
									this.us[j][2] = 'F';
									favor = favor + 1;
								} else if (this.votados[t].vote_category.id == 2) {
									this.us[j][2] = 'C';
									contra = contra + 1;
								} else if (this.votados[t].vote_category.id == 3) {
									this.us[j][2] = 'V';
									absten = absten + 1;
								}
							}
						}
					}

					for (let t = 0; t < this.votados.length; t++) {
						for (let j = 0; j < this.user_presidente.length; j++) {
							if (this.user_presidente[j].name == this.votados[t].user.name) {
								if (this.votados[t].vote_category.id == 1) {
									this.user_presidente[j][2] = 'F';
									favor = favor + 1;
								} else if (this.votados[t].vote_category.id == 2) {
									this.user_presidente[j][2] = 'C';
									contra = contra + 1;
								} else if (this.votados[t].vote_category.id == 3) {
									this.user_presidente[j][2] = 'V';
									absten = absten + 1;
								}
							}
						}
					}
					this.Favor = favor;
					this.Contra = contra;
					this.Absten = absten;
					this.TotalVote = favor + contra + absten;
				});
			}
		},
	},
};
</script>
<template>
  <div>
    <div>
      <div v-if="!this.StoreAll.votacon" class="d-flex justify-content-center">
        <span style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;"> 
          AGUARDANDO O INICIO DA VOTAÇÃO</span>
      </div>

      <div v-if="this.StoreAll.votacon && Loading" class="d-flex justify-content-center">
        <span style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;"> 
          CARREGANDO VEREADORES...</span>
      </div>
      
      <div v-if="this.StoreAll.votacon && !this.StoreAll.vote_secret && !Loading">
        <div class="d-flex" style="margin-top: 5px; border-top: 2px solid #005c9f; border-bottom: 2px solid #005c9f;">
          <div v-for="user in user_presidente" :key="user" class="pt-1 pb-1" >

            <div class="ml-4 d-flex justify-content-start" style="border-radius: 10px; width: clamp(0.1vw, 20vw, 380px);">
              <div class="text-center">
                <div>
                  <p class="text-white ml-3 mb-0" style="font-size:clamp(0vw, 2.2vw, 2.5vw);">
                    <b>VER. PRESIDENTE</b>
                  </p>
                </div>


                <img :src="this.StoreAll.Dochost+user.path_image" class="rounded-circle justify-content-start display_fast_vote"
                  style="object-fit: cover;" alt="user photo" />

                <div class="">
                  <span class="text-uppercase text-white font-weight-bold"
                    style="font-size: clamp(0.99vw, 0vw, 1vw);">{{ user.nickname }} - {{ user.category_party.name_party }}</span>
                </div>
                <div>
                  <span v-if="user[2] != 'F' && user[2] != 'C' && user[2] != 'V'" id="status" class="text-white pl-2 pr-2 font-weight-bold"
                    style="font-size: clamp(1vw, 1.2vw, 1.5vw); background-color: rgb(235, 210, 31); border-radius: 10px">AGUARDANDO VOTO...</span>
                </div>
                <div>
                  <span v-if="user[2] == 'F'" id="status" class="text-white pl-2 pr-2 font-weight-bold bg-success"
                    style="font-size: clamp(1vw, 1.2vw, 1.5vw); border-radius: 10px">A FAVOR</span>
                </div>
                <div>
                  <span v-if="user[2] == 'C'" id="status" class="text-white pl-2 pr-2 font-weight-bold bg-dangerlegis"
                    style="font-size: clamp(1vw, 1.2vw, 1.5vw); border-radius: 10px">CONTRA</span>
                </div>
                <div>
                  <span v-if="user[2] == 'V'" id="status" class="text-white pl-2 pr-2 font-weight-bold bg-secondary"
                    style="font-size: clamp(1vw, 1.2vw, 1.5vw); border-radius: 10px;">ABSTENÇÃO</span>
                </div>
              </div>
            </div>
          </div>

          <div style="margin-left: 3%; margin-top: 1%;">
          <div class="d-flex text-black font-weight-bold"
          style="background-color: #ebd21f; border-radius: 5px 5px 0.1vw 0.1vw; font-family: 'Old Standard TT', serif; width: clamp(0.1vw, 70vw, 1344px);">
            <div style="width: 100%; white-space: normal; overflow: hidden; text-overflow: ellipsis;">
              <span class="ml-2 font_element_vote"> <b> {{ doc_name.substring(0, 200) }}{{doc_name.length > 200 ? '...' : ''}} </b></span> 
            </div>
          </div>

          <div class="d-flex">

            <div class="d-flex justify-content-center p-4 text-center shadow bg-success" style="width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-white font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                SIM
              </div>
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-white font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{Favor}}</span>
              </div>
            </div>

            <div class="d-flex justify-content-center p-4 text-center shadow bg-dangerlegis" style="width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-white font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                NÃO
              </div>
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
              <span class="text-white font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{ Contra }} </span>
              </div>
            </div>




            <div class="d-flex justify-content-center p-4 text-center shadow" style="background-color: #bfbfbf; width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-black font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                ABS 
              </div> 
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{ Absten }} </span>
              </div>
              </div>


            <div class="d-flex justify-content-center p-4 text-center shadow" style="background-color: white; width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-black font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                TOTAL 
              </div> 
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
               {{ TotalVote }}  </span>
              </div>
              </div>

          </div>
       
          </div>
          
        </div>

        <!-- div presidente acima -->
        <div>
          <p class="text-white mb-0" style="font-size:clamp(0vw, 2.2vw, 2.5vw);margin-left: clamp(5%, 5%, 7%);">
            <b>VEREADORES</b>
          </p>
        </div>
        <div class="d-flex justify-content-center flex-wrap" style="border-bottom: 2px solid #005c9f">
          <div v-for="user in User_Tot" :key="user" class="mb-0 display_body_nine" :class="{ 'col-3': this.StoreAll.Large_host == 9, 'col-1-5-users-11': this.StoreAll.Large_host == 11, 'col-2': this.StoreAll.Large_host == 13, 'col-1-5-users-14': this.StoreAll.Large_host == 14, 'col-1-5-users-17': this.StoreAll.Large_host == 17 }"> <!-- col-3 para 9 -->
            <div class="" style="border-radius: 10px;">
              <div class="text-center">
                <img v-if="user[1] == 'P'" :src="this.StoreAll.Dochost+user.path_image"
                  class="rounded-circle justify-content-start display_fast_vote" style="object-fit: cover;"
                  alt="user photo" />

                <div>
                  <img v-if="user[1] == 'A'" :src="this.StoreAll.Dochost+user.path_image"
                    class="rounded-circle justify-content-start display_fast_vote"
                    style="opacity: 50%; object-fit: cover;" alt="user photo" />
                </div>

                <div class="">
                  <span v-if="user[1] == 'P'" class="text-uppercase text-white font-weight-bold"
                    style="font-size: clamp(0.99vw, 0vw, 1vw);">{{ user.nickname }} - {{ user.category_party.name_party }}</span>
                  <span v-if="user[1] == 'A'" class="text-uppercase text-white font-weight-bold"
                    style="font-size: clamp(0.99vw, 0vw, 1vw); opacity: 50%;">{{ user.nickname }} - {{ user.category_party.name_party }}</span>
                </div>
                <div>
                  <span v-if="user[1] == 'A'" id="status" class="
                        text-white
                        pl-2
                        pr-2
                        font-weight-bold
                        bg-dangerlegis
                      " style="font-size: clamp(1vw, 1.1vw, 1.5vw); border-radius: 10px; opacity: 50%;">AUSENTE</span>
                </div>
                <div>
                  <span v-if="user[2] == 'N' && user[1] == 'P'" id="status"
                    class="text-white pl-2 pr-2 font-weight-bold"
                    style="font-size: clamp(1vw, 1.1vw, 1.5vw); background-color: rgb(235, 210, 31); border-radius: 10px">AGUARDANDO VOTO...</span>
                </div>
                <div>
                  <span v-if="user[2] == 'F' && user[1] == 'P'" id="status"
                    class="text-white pl-2 pr-2 font-weight-bold bg-success"
                    style="font-size: clamp(1vw, 1.1vw, 1.5vw); border-radius: 10px">A FAVOR</span>
                </div>
                <div>
                  <span v-if="user[2] == 'C' && user[1] == 'P'" id="status"
                    class="text-white pl-2 pr-2 font-weight-bold bg-dangerlegis"
                    style="font-size: clamp(1vw, 1.1vw, 1.5vw); border-radius: 10px">CONTRA</span>
                </div>
                <div>
                  <span v-if="user[2] == 'V' && user[1] == 'P'" id="status"
                    class="text-white pl-2 pr-2 font-weight-bold bg-secondary"
                    style="font-size: clamp(1vw, 1.1vw, 1.5vw); border-radius: 10px">ABSTENÇÃO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div v-if="this.StoreAll.votacon && this.StoreAll.vote_secret && !Loading">
          <div class="d-flex justify-content-center" style="margin-top: 5px; border-top: 2px solid #005c9f;">
          
          <div style="margin-left: 3%; margin-top: 1%;">
          <div class="d-flex text-black font-weight-bold mt-3"
          style="background-color: #ebd21f; border-radius: 5px 5px 0.1vw 0.1vw; font-family: 'Old Standard TT', serif; width: clamp(0.1vw, 70vw, 1344px);">
            <div style="width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <span class="ml-2 font_element_vote"> <b> {{ doc_name.substring(0, 80) }}{{doc_name.length > 50 ? '...' : ''}} </b></span> 
            </div> 
            

          </div>

          <div class="d-flex">

            <div class="d-flex justify-content-center p-4 text-center shadow bg-success" style="width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-white font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                SIM
              </div>              
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-white font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{Favor}}</span>
              </div>   
            </div>

            <div class="d-flex justify-content-center p-4 text-center shadow bg-dangerlegis" style="width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-white font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                NÃO 
              </div> 
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
              <span class="text-white font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{ Contra }} </span>
              </div>
            </div>




            <div class="d-flex justify-content-center p-4 text-center shadow" style="background-color: #bfbfbf; width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-black font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                ABS 
              </div> 
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
                {{ Absten }} </span>
              </div>
              </div>


            <div class="d-flex justify-content-center p-4 text-center shadow" style="background-color: white; width: clamp(0.1vw, 17.5vw, 450px); height: clamp(0.1vw, 9vw, 160px); border-radius: 0.1vw 0 0 5px; ">
              <div class="text-black font-weight-bold" style="margin-top: 5%; font-size: clamp(1vw, 2vw, 3vw); font-family: 'Old Standard TT', serif;"> 
                TOTAL 
              </div> 
              <div class="ml-2" style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 4vw, 4vw);font-family: 'Old Standard TT', serif; position: absolute; transform: translate(-50%, -10%);">
               {{ TotalVote }}  </span>
              </div>
              </div>

          </div>
       
          </div>
          
        </div>
        <div class="d-flex justify-content-center mt-5">
          <div class="text-center mt-5" style="background-color: aliceblue;width: 50%; font-family: 'NucleoIcons';">
            <h1 style="color: red;"> VOTAÇÃO SECRETA </h1>
          </div>
        </div>
    </div>
   
    </div>

    <div v-if="this.StoreAll.vote_secret && !Loading" class="d-flex justify-content-between footers">
      <div style="display: flex;">
        <span class="text-white text-center"
        style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid; padding-top: 2%;">
          {{ dia }} <br />
          {{ mes }}
        </span>

        <span class="text-white d-flex justify-content-center"
        style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem; padding-top: 2%;">
          {{ time }}
        </span>

        
        
    </div>

        <div>
          <span class="text-center text-white text-uppercase" style="font-size:clamp(1.8em, 1em + 1vw, 1.5em); font-family: 'Old Standard TT', serif;"> 
            TIPO: {{ category_doc}} 
          </span>
          <span class="text-center text-white text-uppercase" style="display: block; font-size:clamp(2em, 1em + 1vw, 1.5em); font-family: 'Old Standard TT', serif;"> 
            AUTOR: {{ autor }}
          </span>
          </div> 


    <div class="text-white text-center d-flex justify-content-center"
      style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em); padding-top: 0.5%;">
        Tempo de sessão <br />
        {{ timer_session }}
    </div>
    </div>
    <div v-if="!this.StoreAll.vote_secret && !Loading" class="d-flex justify-content-between">
      <div style="display: flex;">
        <span class="text-white text-center"
        style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid; padding-top: 2%;">
          {{ dia }} <br />
          {{ mes }}
        </span>

        <span class="text-white d-flex justify-content-center"
        style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem; padding-top: 2%;">
          {{ time }}
        </span>

        
        
    </div>

    <div style="width: 45%;">
      <div class="d-flex justify-content-center">
        <span class="text-center text-white text-uppercase" style="font-size:clamp(1.8em, 1em + 1vw, 1.5em); font-family: 'Old Standard TT', serif;"> 
              TIPO: {{ category_doc}} 
            </span>
      </div>
        <div>
          <div style="display: block; text-align: center; font-size:clamp(2em, 1em + 1vw, 1.5em); font-family: 'Old Standard TT', serif; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <span class="text-center text-white text-uppercase"> <b> AUTOR(ES): {{ autor.substring(0, 80) }}{{autor.length > 50 ? '...' : ''}} </b></span> 
            </div> 
          </div> 
        </div>


    <div class="text-white text-center d-flex justify-content-center"
      style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em); padding-top: 0.5%;">
        Tempo de sessão <br />
        {{ timer_session }}
    </div>
    
</div>

  </div>
</template>
<style>
.text-black {
  color: black;
}

.display_fast_vote {
  width: clamp(0.1vw, 8vw, 300px);
  height: clamp(0.1vw, 8vw, 300px);
}

.display_type_doc {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 75%);
  }

  .footers {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}

@media only screen and (max-width: 1600px) {
  .display_fast_vote {
    width: clamp(0.1vw, 7.6vw, 300px);
    height: clamp(0.1vw, 7.6vw, 300px);
  }
  .display_type_doc {
    top: 33%;
  }
}

@media only screen and (max-width: 1380px) {
  .display_fast_vote {
    width: clamp(0.1vw, 7vw, 300px);
    height: clamp(0.1vw, 7vw, 300px);
  }
  .display_type_doc {
    top: 33%;
  }
}

@media only screen and (max-width: 1280px) {
  .display_fast_vote {
    width: clamp(0.1vw, 6.8vw, 300px);
    height: clamp(0.1vw, 6.8vw, 300px);
  }
}
</style>
