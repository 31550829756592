<script>
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvHeader',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			brasao: '',
			tribuna: false,
			vote: false,
			discut: false,
			explanation: false,
			Ordem: false,
			session: { name: 'Aguarde o quorum ser iniciado...', },
			socket_ids: [],
		};
	},

	watch: {
		'StoreAll.tribuna': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.tribuna = true;
			} else {
				this.tribuna = false;
			}
		},

		'StoreAll.vote': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.vote = true;
			} else {
				this.vote = false;
			}
		},

		'StoreAll.discut': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.discut = true;
			} else {
				this.discut = false;
			}
		},

		'StoreAll.explanation': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.explanation = true;
			} else {
				this.explanation = false;
			}
		},

		'StoreAll.Ordem': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.Ordem = true;
			} else {
				this.Ordem = false;
			}
		},

	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.GetSession();
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.tribuna = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.tribuna = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.explanation = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.explanation = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.discut = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 30) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.discut = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 31) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.Ordem = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 34) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.Ordem = false;
						this.socket_ids.push(e.event_id);
					}

				});
			}}, 500);

		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);

				this.StoreAll.socket.on('6', (e) => {
					if (this.vote === false) {
						this.vote = true;
					}
				});

				this.StoreAll.socket.on('8', (e) => {
					if (this.vote === true) {
						this.vote = false;
					}
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.Legendas();
		this.BrasaoAndName();
	},

	methods: {
		BrasaoAndName(){
			if(this.StoreAll.host == 'dev'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'teste'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'jc'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jc.png');

			}else if(this.StoreAll.host == 'bodo'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'pedroavelino'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pa.png');

			}else if(this.StoreAll.host == 'pedrapreta'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pp.png');

			}else if(this.StoreAll.host == 'campogrande'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_cg.png');

			}else if(this.StoreAll.host == 'parazinho'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_parazinho.png');

			}else if(this.StoreAll.host == 'local'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_afonso.png');

			}else if(this.StoreAll.host == 'macaiba'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_macaiba.png');

			}else if(this.StoreAll.host == 'veracruz'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_veracruz.png');

			}else if(this.StoreAll.host == 'jandaira'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jd.png');

			}else if(this.StoreAll.host == 'triunfo'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_tp.png');

			}else if(this.StoreAll.host == 'upanema'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_upanema.png');

			}else if(this.StoreAll.host == 'ja'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_ja.png');

			}else if(this.StoreAll.host == 'angicos'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_angicos.png');

			}else if(this.StoreAll.host == 'crv'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_crv.png');

			}else if(this.StoreAll.host == 'goianinha'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_goianinha.png');
			}
			else if(this.StoreAll.host == 'jucurutu'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jucurutu.png');
			}
			else if(this.StoreAll.host == 'odb'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_odb.png');
			}
			else if(this.StoreAll.host == 'sm'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sm.png');
			}
			else if(this.StoreAll.host == 'lp'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_lagoapedra.png');
			}
			else if(this.StoreAll.host == 'sr'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sr.png');
			}
			else if(this.StoreAll.host == 'bf'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bf.png');
			}
			else if(this.StoreAll.host == 'sbn'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sbn.png');
			}
			else if(this.StoreAll.host == 'pureza'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pureza.png');
			}
			else if(this.StoreAll.host == 'abapp'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_afonso.png');
			}
		},

		Legendas() {
			setTimeout(() => {
				if (this.StoreAll.tribuna === true) {
					this.tribuna = true;
				} else {
					this.tribuna = false;
				}

				if (this.StoreAll.vote === true) {
					this.vote = true;
				} else {
					this.vote = false;
				}

				if (this.StoreAll.discut === true) {
					this.discut = true;
				} else {
					this.discut = false;
				}

				if (this.StoreAll.explanation === true) {
					this.explanation = true;
				} else {
					this.explanation = false;
				}

				if (this.StoreAll.Ordem === true) {
					this.Ordem = true;
				} else {
					this.Ordem = false;
				}

			}, 3000);
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
		},
	},
};
</script>
<template>
  <div>
    <div>
      <div class="d-flex ml-1">
        <img :src="brasao" class="justify-content-start mt-1 display_image" />
        <div style="display: grid">
          <span class="text-white ml-2" style="font-size: clamp(0.1vw, 2.5vw, 40px); font-weight: 700 !important"> {{ this.StoreAll.name_camara }}</span>
          <span class="text-white ml-2" style="font-size: clamp(0.1vw, 2vw, 40px); font-weight: 700 !important">{{
              session.name
          }}</span>
        </div>
        <h1 v-if="(vote === false && tribuna == false && discut == false && explanation == false && Ordem === false)" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">QUÓRUM</h1>
        <h1 v-else-if="vote === true && Ordem === false" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">VOTAÇÃO</h1>
        <h1 v-else-if="tribuna === true && Ordem === false" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">TRIBUNA</h1>
        <h1 v-else-if="discut === true && Ordem === false" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">DISCUSSÃO</h1>
        <h3 v-else-if="explanation === true && Ordem === false" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">EXPLANAÇÕES</h3>
        <h3 v-else-if="Ordem === true" class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">ORDEM</h3>
        <h3 v-else class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;"></h3>
      </div>
    </div>
  </div>
</template>
<style>
.text-black {
  color: black;
}

.display_image {
  width: 160px;
  height: 130px;
}

@media only screen and (max-width: 1650px) {
  .display_image {
    width: 135px;
    height: 110px;
  }
}

@media only screen and (max-width: 1380px) {
  .display_image {
    width: 120px;
    height: 105px;
  }
}

@media only screen and (max-width: 1280px) {
  .display_image {
    width: 120px;
    height: 95px;
  }
}
</style>
